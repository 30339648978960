export const tools = {
  trackingSheet: { path: "/tracking-sheet" },
  aiContactsFilter: { path: "/ai-contacts-filter" },
  drUpdate: { path: "/dr-update" },
  projects: { path: "/projects" },
  updateTracking: { path: "/update-tracking" },
  aiKeywordRelevance: { path: "/ai-keyword-relevance" },
  visitedInfo: { path: "/visited-info" },
};

export const navbarTools = {
  trackingSheet: tools.trackingSheet,
  aiContactsFilter: tools.aiContactsFilter,
  projects: tools.projects,
  dataToolsOverview: { path: "/data-update-tools" },
};
